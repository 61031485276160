import { parseISO } from "date-fns";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { RichText } from "prismic-reactjs";
import * as React from "react";
import styled from "styled-components";
import {
  BlogCard,
  Button,
  Column,
  CurvedSectionDivider,
  FormattedText,
  Grid,
  Gutter,
  HelmetSeo,
  HorizontalAlign,
  Layout,
  Line,
  MaxWidth,
  PageMaxWidth,
  Section,
  SectionQuotes,
  VideoModal,
} from "../components";
import Text from "../components/Text";
import { Color, Dimen } from "../constants";
import { fixedImage, fluidImage } from "../prismic-tools";

type Props = {
  data: GatsbyTypes.AboutQuery;
};

export const query = graphql`
  query About {
    prismicAboutPageV2 {
      data {
        backings_title
        backings_description {
          raw
        }
        backings_image {
          alt
          dimensions {
            width
            height
          }
          url
        }
        cards {
          card_date
          card_image {
            alt
            dimensions {
              height
              width
            }
            url
          }
          card_link
          card_read_time
          card_text
          card_type
        }
        featured_description {
          raw
        }
        featured_title

        glances {
          glance_description {
            raw
          }
          glance_headline
        }
        glances_title

        hero_title

        hero_video_button

        hero_image {
          alt
          dimensions {
            height
            width
          }
          url
        }

        histories {
          history_year
          history_description {
            raw
          }
          history_icon {
            alt
            dimensions {
              height
              width
            }
            url
          }
        }
        histories_title

        how_we_do
        how_we_do_description {
          raw
        }
        map_email
        map_location {
          raw
        }
        map_title
        missions {
          mission_description {
            raw
          }
          mission_title
        }
        quotes {
          quote_author_image {
            alt
            dimensions {
              width
              height
            }
            url
          }
          quote_author_name
          quote_author_role
          quote_button_text
          quote_content {
            raw
          }
          quote_title
        }
        story_description {
          raw
        }

        story_image {
          alt
          dimensions {
            width
            height
          }
          url
        }
        story_title
        values {
          value_description
          value_icon
        }
        values_title

        what_we_do_title
        what_we_do_description {
          raw
        }

        seo_title
        seo_description
        share_image {
          alt
          dimensions {
            width
            height
          }
          url
        }
      }
    }
  }
`;

export default function AboutCompany({ data }: Props) {
  const about = data.prismicAboutPageV2?.data;
  if (!about) throw new Error("about page missing");

  const [videoVisible, setVideoVisible] = React.useState<boolean>(false);

  const valuesColours = {
    0: "blue1",
    1: "lightBlue1",
    2: "violet1",
    3: "green1",
  };

  return (
    <Layout showDemoBanner footerBackColor={Color.light2}>
      <HelmetSeo {...about} />
      <StyledHero>
        <StyledLayer>
          <StyledImg fluid={fluidImage(about.hero_image, 2000)} />
        </StyledLayer>

        <StyledLayer>
          <PageMaxWidth>
            <Gutter size={3} md={2} />
            <MaxWidth width={998}>
              <HorizontalAlign align="center">
                <Text typeface="h1" bold color="light1" align="center" as="h1">
                  {about.hero_title}
                </Text>
              </HorizontalAlign>
            </MaxWidth>
            <Gutter size={1.5} />
            <HorizontalAlign align="center">
              <Button
                href="https://shipamax.wistia.com/medias/6fd59i033f"
                onClick={(ev) => {
                  setVideoVisible(true);
                  ev.preventDefault();
                }}
                colorTheme="blue"
                label={about.hero_video_button}
                icon="play"
              />
            </HorizontalAlign>
          </PageMaxWidth>
        </StyledLayer>
      </StyledHero>

      <Section background="light2">
        <PageMaxWidth>
          <div style={{ position: "relative", marginTop: "-100px" }}>
            <Grid>
              {about.missions?.map((m, i) => {
                if (!m || !m.mission_description) return null;
                const colorTheme = i === 0 ? "blue1" : "violet1";
                return (
                  <Column size={6} key={i}>
                    <StyledMissionCard>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <div>
                          <StyledMissionCircle $color={Color[colorTheme]} />
                        </div>
                        <div className="main">
                          <Text typeface="h4" bold color={colorTheme} as="h2">
                            {m.mission_title}
                          </Text>
                          <Gutter size={0.75} />
                          <FormattedText>
                            <RichText render={m.mission_description.raw} />
                          </FormattedText>
                        </div>
                      </div>
                    </StyledMissionCard>
                  </Column>
                );
              })}
            </Grid>
          </div>
        </PageMaxWidth>
      </Section>

      <Section background="light2">
        <Gutter size={4} />
        <PageMaxWidth>
          <Grid>
            <Column size={6}>
              <Text typeface="h4" bold as="h2">
                {about.what_we_do_title}
              </Text>
            </Column>
            <Column size={6}>
              <FormattedText>
                <RichText render={about.what_we_do_description?.raw} />
              </FormattedText>
            </Column>
          </Grid>
          <Gutter size={4} md={1} />
          <Line color="orange1" />
          <Gutter size={4} md={1} />
          <Grid>
            <Column size={6}>
              <Text typeface="h4" bold as="h2">
                {about.how_we_do}
              </Text>
            </Column>
            <Column size={6}>
              <FormattedText>
                <RichText render={about.how_we_do_description?.raw} />
              </FormattedText>
            </Column>
          </Grid>
        </PageMaxWidth>
        <Gutter size={4} />
      </Section>

      <Section background="light2">
        <PageMaxWidth>
          <HorizontalAlign align="center">
            <Text typeface="h2" bold as="h2">
              {about.glances_title}
            </Text>
          </HorizontalAlign>
          <Gutter size={2} />
          <Grid>
            {about.glances?.map(
              (glance) =>
                glance && (
                  <Column size={4} key={glance.glance_headline}>
                    <StyledGlanceCardWrap>
                      <StyledGlanceCardShadow />
                      <StyledGlanceCard>
                        <StyledGlanceHeadline>
                          <Text typeface="h4" bold color="blue1" as="h2">
                            {glance.glance_headline}
                          </Text>
                        </StyledGlanceHeadline>
                        <Gutter size={0.25} />
                        <div style={{ flex: 2 }}>
                          <FormattedText>
                            <RichText render={glance.glance_description?.raw} />
                          </FormattedText>
                        </div>
                      </StyledGlanceCard>
                    </StyledGlanceCardWrap>
                  </Column>
                )
            )}
          </Grid>
        </PageMaxWidth>
        <Gutter size={4} />
      </Section>

      <Section background="light2">
        <PageMaxWidth>
          <HorizontalAlign align="center">
            <Text typeface="h2" as="h2" bold>
              {about.histories_title}
            </Text>
          </HorizontalAlign>
          <Gutter size={2} />
          <MaxWidth width={650}>
            {about.histories?.map(
              (history) =>
                history && (
                  <StyledHistoryContainer key={history.history_year}>
                    <StyledYearContainer>
                      {history.history_year ? (
                        <StyledYearSubContainer>
                          <Text typeface="h4" as="h4" bold>
                            {history.history_year}
                          </Text>
                          <StyledDottedLine />
                        </StyledYearSubContainer>
                      ) : null}
                    </StyledYearContainer>
                    <StyledHistorySubContainer>
                      <div style={{ width: "81px" }}>
                        <StyledCircleWithEdge $color={Color.light1}>
                          {history.history_icon && (
                            <StyledHistoryIcon>
                              <img src={history.history_icon.url} />
                            </StyledHistoryIcon>
                          )}
                        </StyledCircleWithEdge>
                      </div>
                      <Gutter />
                      <StyledHistoryDesc>
                        <FormattedText>
                          <RichText render={history.history_description?.raw} />
                        </FormattedText>
                      </StyledHistoryDesc>
                    </StyledHistorySubContainer>
                  </StyledHistoryContainer>
                )
            )}
          </MaxWidth>
          <Gutter size={4} />
        </PageMaxWidth>
      </Section>

      <Section background="light2">
        <PageMaxWidth>
          <MaxWidth width={712}>
            <HorizontalAlign align="center">
              <Text typeface="h4" as="h4" bold>
                {about.story_title}
              </Text>
            </HorizontalAlign>
            <Gutter size={2} />
            <MaxWidth width={1200}>
              <StyledStoryImage>
                <Img fluid={fluidImage(about.story_image, 1080)} />
              </StyledStoryImage>
            </MaxWidth>
            <Gutter size={2} />
            <Line color="orange1" />
            <Gutter size={2} />
            <FormattedText>
              <RichText render={about.story_description?.raw} />
            </FormattedText>
          </MaxWidth>
        </PageMaxWidth>
        <Gutter size={4} />
      </Section>

      <CurvedSectionDivider
        position="top"
        frontColor="newDark2"
        backColor="light2"
      />
      <Section background="dark">
        <PageMaxWidth>
          <HorizontalAlign align="center">
            <Text typeface="h3" bold color="light1" as="h2">
              {about.values_title}
            </Text>
          </HorizontalAlign>
          <Gutter size={2} />
          <Grid>
            {about.values?.map(
              (value, i) =>
                value && (
                  <React.Fragment key={value.value_description}>
                    <Column size={3}>
                      <StyledValuesDesc>
                        <Text
                          bold
                          typeface="h5"
                          color={
                            valuesColours[
                              i as keyof typeof valuesColours
                            ] as keyof typeof Color
                          }
                          align="center"
                          as="h2"
                        >
                          {value.value_description}
                        </Text>
                      </StyledValuesDesc>
                      <Gutter />
                      <HorizontalAlign align="center">
                        <StyledCircleOutline
                          $color={
                            valuesColours[
                              i as keyof typeof valuesColours
                            ] as keyof typeof Color
                          }
                        >
                          <div>
                            <img
                              src={require(`../../img/pages/about/icon_${value.value_icon}.svg`)}
                            />
                          </div>
                        </StyledCircleOutline>
                      </HorizontalAlign>
                    </Column>
                  </React.Fragment>
                )
            )}
          </Grid>
          <Gutter />
        </PageMaxWidth>
        <Gutter />
      </Section>

      <CurvedSectionDivider
        position="bottom"
        frontColor="newDark2"
        backColor="light2"
      />

      <Section background="light2">
        <Gutter size={4} />

        <PageMaxWidth>
          <Grid>
            <Column size={6}>
              <Text typeface="h2" bold as="h2">
                {about.backings_title}
              </Text>
              <Gutter />
              <FormattedText>
                <RichText render={about.backings_description?.raw} />
              </FormattedText>
            </Column>
            <Column size={6}>
              {about.backings_image && (
                <MaxWidth width={528}>
                  <Gutter size={0} md={3} />
                  <Img fluid={fluidImage(about.backings_image, 528)} />
                </MaxWidth>
              )}
            </Column>
          </Grid>
          <Gutter size={4} />
        </PageMaxWidth>
      </Section>

      <Section background="light">
        <StyledMapWrap>
          <PageMaxWidth>
            <Grid alignColumns="center">
              <Column size={6}>
                <Gutter size={0} md={2} />
                <Text typeface="h2" bold as="h2">
                  {about.map_title}
                </Text>
                <Gutter />
                <StyledAddressContainer>
                  <FormattedText>
                    <RichText render={about.map_location?.raw} />
                  </FormattedText>
                </StyledAddressContainer>
                <Gutter />
                <Text bold>
                  <a href={`mailto:${about.map_email}`}>{about.map_email}</a>
                </Text>
                <Gutter size={0} md={2} />
              </Column>
              <Column size={6}>
                <StyledMap>
                  <div id="map">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9931.130998156985!2d-0.085917!3d51.5172016!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf03b10b91e75d51d!2sShipamax+Ltd.!5e0!3m2!1sen!2suk!4v1563236193418!5m2!1sen!2suk"
                      frameBorder={0}
                      allowFullScreen
                      title={"shipmap"}
                    />
                  </div>
                </StyledMap>
              </Column>
            </Grid>
          </PageMaxWidth>
          <StyledMapCurve>
            <CurvedSectionDivider
              position="top"
              frontColor="newDark2"
              backColor="transparent"
            />
          </StyledMapCurve>
        </StyledMapWrap>
      </Section>
      <Section background="dark">
        <PageMaxWidth>
          <Gutter size={0} md={3} />
          {about.quotes && (
            <SectionQuotes
              quotes={about.quotes}
              buttonLabel="Live Shipamax Roles"
              buttonHref="https://angel.co/company/shipamax"
            />
          )}
          <Gutter />
        </PageMaxWidth>
      </Section>
      <CurvedSectionDivider
        position="bottom"
        frontColor="newDark2"
        backColor="light2"
      />
      <Section background="light2">
        <Gutter size={4} />
        <PageMaxWidth>
          <HorizontalAlign align="center">
            <Text typeface="h2" bold as="h2">
              {about.featured_title}
            </Text>
            <Gutter />
            <FormattedText>
              <RichText render={about.featured_description?.raw} />
            </FormattedText>
          </HorizontalAlign>
          <Gutter size={2} />
          <Grid justifyColumns="center">
            {about.cards?.map(
              (c) =>
                c && (
                  <Column size={4}>
                    <BlogCard
                      image={c.card_image}
                      href={c.card_link}
                      date={c.card_date ? parseISO(c.card_date) : undefined}
                      title={c.card_text}
                      readTime={c.card_read_time}
                      description={c.card_text}
                    />
                  </Column>
                )
            )}
          </Grid>
          <Gutter />
        </PageMaxWidth>
      </Section>

      <VideoModal
        wistiaId="6fd59i033f"
        visible={videoVisible}
        onDismiss={() => setVideoVisible(false)}
      />
    </Layout>
  );
}

const StyledHero = styled("section")`
  position: relative;
  min-height: 640px;
  background-color: ${Color.newDark2};

  @media (max-width: ${Dimen.breakpointDownMd}px) {
    min-height: 480px;
  }
`;

const StyledLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  overflow: hidden;
`;

// Despite fetching the image at a fixed resolution, we want its width to be
// freely adjusted to fill up the available space.
const StyledImg = styled(Img)`
  width: 100% !important;
  height: 100% !important;
` as any;

const StyledMissionCard = styled.div`
  display: block;
  min-height: 203px;
  background-color: ${Color.light1};
  box-shadow: 0px 13px 10px rgba(19, 19, 31, 0.07);
  border-radius: 8px;
  padding: 30px 35px;

  .main {
    padding: 0 25px;
    margin-top: -5px;
    max-width: 400px;
  }

  &:hover {
    svg {
      transform: translateX(7px);
    }
  }

  @media (max-width: ${Dimen.breakpointDownMd}px) {
    min-height: auto;
  }
`;
const StyledMissionCircle = styled.div<{ $color: string }>`
  width: 32px;
  height: 32px;
  background-color: ${(p) => p.$color};
  border-radius: 50% 50% 0% 50%;
  display: flex;
  justify-content: center;
  box-shadow: 0px 12px 25px rgba(19, 19, 31, 0.05);
`;

const StyledGlanceCardWrap = styled.div`
  position: relative;
`;

const StyledGlanceCardShadow = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 12px;
  right: 12px;
  border-radius: 8px;
  box-shadow: 0px 8px 30px rgba(82, 87, 102, 0.15);
  z-index: 1;
`;

const StyledGlanceCard = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;
  height: 134px;
  background-color: ${Color.light1};
  position: relative;
  padding: ${Dimen.gutterWidth}px;
  margin: 10px 0;
  z-index: 2;

  @media (max-width: ${Dimen.breakpointDownMd}) {
    margin: 0;
  }
`;
const StyledGlanceHeadline = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

const StyledHistoryContainer = styled.div`
  display: flex;
  flex: 2;

  @media (max-width: ${Dimen.breakpointDownMd}px) {
    display: block;
  }
`;
const StyledHistorySubContainer = styled.div`
  display: flex;
  flex: 3;
  margin-top: 26px;

  @media (max-width: ${Dimen.breakpointDownMd}px) {
    display: flex;
    margin-top: 20px;
  }
`;
const StyledHistoryIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledHistoryDesc = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  min-height: 68px;
`;
const StyledYearContainer = styled.div`
  display: flex;
  flex: 2;
`;
const StyledYearSubContainer = styled.div`
  display: flex;
  height: 92px;
  flex-direction: column;
  justify-content: flex-end;

  @media (max-width: ${Dimen.breakpointDownMd}px) {
    height: 68px;
  }
`;

const StyledStoryImage = styled.div`
  img {
    border-radius: 8px;
  }
`;

const StyledValuesDesc = styled.div`
  height: 60px;
  display: flex;
  justify-content: center;
`;
const StyledAddressContainer = styled.div`
  width: 40%;
`;
const StyledCircleOutline = styled.div<{ $color: keyof typeof Color }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  border: 2px solid ${(p) => Color[p.$color]};
  border-radius: 50%;
  box-shadow: 0px 6px 17px #000000;

  @media (max-width: ${Dimen.breakpointDownMd}px) {
    flex-direction: row-reverse;
    margin: 0 auto;
    width: 70px;
    height: 70px;
    p {
      flex: 1;
    }

    img {
      width: 27px;
    }
  }
`;

const StyledCircleWithEdge = styled.div<{ $color: string }>`
  width: 68px;
  height: 68px;
  background-color: ${(p) => p.$color};
  border-radius: 50% 50% 50% 0%;
  display: flex;
  justify-content: center;
  box-shadow: 0px 12px 25px rgba(19, 19, 31, 0.05);
`;

const StyledDottedLine = styled.div`
  border: 1px dashed ${Color.orange1};
  width: 240px;

  @media (max-width: ${Dimen.breakpointDownMd}px) {
    width: 700px;
  }
`;

const StyledMapWrap = styled.div`
  position: relative;
`;

const StyledMap = styled.div`
  height: 828px;

  > div {
    position: absolute;
    top: 0;
    left: 50%;
    right: 0;
    bottom: 0;
  }

  iframe {
    width: 100%;
    height: 100%;
    border: 0;
    margin: 0;
  }

  @media (max-width: ${Dimen.breakpointDownMd}px) {
    height: 392px;

    > div {
      position: static;
      margin: 0 -${Dimen.gutterWidth}px;
      height: 100%;
    }
  }
`;

const StyledMapCurve = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;

  @media (max-width: ${Dimen.breakpointDownMd}px) {
    display: none;
  }
`;
